.breadCrumbs {
  margin-bottom: 20px !important;

  .breadCrumbsLink {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: #000;

    &:hover {
      text-decoration: underline;
    }
  }
}
