.block {
    display: flex;
    flex-direction: column;
}

.body {
    border-top: 1px solid grey;
    margin-top: 25px;
    padding-top: 25px;
    display: flex;
    flex-direction: column;
    gap: 14px;
    .title {
        color: grey;
    }
}