.form {
  width: 700px;

  .formFieldsWrapper {
    max-height: 500px;
    overflow: scroll;
    padding: 10px;
    overflow-x: auto;

    .formFields {
      display: flex;
      flex-direction: column;
      gap: 40px;

      .card {
        display: flex;
        flex-direction: column;
        padding: 10px;

        .cardItems {
          display: flex;
          align-items: center;
          gap: 20px;

          .cardInput {
            width: 100%;
            margin-top: 10px;
          }
        }
      }
    }
  }

  .deleteButton {
    cursor: pointer;
  }
}
