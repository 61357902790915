.services {
  border: 1px solid #e3e3e3;
  padding: 10px;
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .container {
    .noContent {
      display: flex;
      justify-content: center;
    }
  }
}

.inputWrapper {
  display: flex;
  justify-content: space-between;
  gap: 25px;
}

.selectIconItem {
  display: flex;
  align-items: center;
  gap: 10px;
  svg {
    height: 3rem;
    width: 3rem;
  }
}

.servicesViewWrapper {
  max-height: 300px;
  overflow-y: auto;
  .serviceItem {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 1;
    .serviceIconBlock {
      display: flex;
      align-items: center;
      gap: 10px;
      svg {
        width: 3rem;
        height: 3rem;
      }
    }
    .serviceActions {
      display: flex;
      cursor: pointer;
      gap: 10px;
      align-items: center;
    }
  }
}

::-webkit-scrollbar {
  width: 3px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #e4e4e4;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #cecece;
}

.serviceName {
  display: flex;
  gap: 20px;
  align-items: center;
}

.popular {
  font-size: 14px;
}

.serviceItemWrapper {
  border-bottom: 1px solid #e3e3e3;
  padding: 15px 10px 10px 10px;
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
}