.linksWrapper {
  height: 95%;

  .linkItem {
    display: flex;
    align-items: center;
    width: 100%;
    border: 1px solid var(--ligth-gray);
    padding: 15px 10px;
    border-radius: 5px;
    margin-bottom: 10px;
    cursor: pointer;
    transition: 0.3s all;
    text-decoration: none;
    color: var(--primary-black);
    font-size: 16px;
    font-weight: bold;

    &:hover {
      box-shadow:
        rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
        rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    }

    .avatar {
      background: none;
    }
  }
}
