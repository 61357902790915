:root {
  --primary-black: #000;
  --primary-gray: #808080;
  --primary-gray-hover: #edf5fe;
  --ligth-gray: #d1d1d1;
  --light-green: #60ff61;
  --blue: #1976d7;
}

ul[role='listbox'] {
  max-height: 400px;
}
