.fileInput {
  overflow: hidden;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  position: absolute;
  bottom: 0;
  left: 0;
  white-space: nowrap;
  width: 1px;
}

.imgPreview {
  display: flex;
  gap: 20px;
  align-items: center;
  margin: 2rem 0;
  .imgContainer {
    height: 50px;
    width: 50px;
    .img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .removeImageButton {
    display: block;
    margin: 0 auto;
  }
}
