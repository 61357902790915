.container {
  max-width: 1000px;
  margin: 0 auto;
  width: 100%;
  .title {
    margin: 30px 0;
  }
  .form {
    margin-bottom: 30px;
  }
}
