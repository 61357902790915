.container {
  width: 350px;
  margin: calc(25% - 275px) auto;
  border: 1px solid #e3e3e3;
  padding: 50px 30px;
  border-radius: 10px;

  box-shadow:
    rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px,
    rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px,
    rgba(0, 0, 0, 0.09) 0px -3px 5px;

  .image {
    width: 120px;
    display: block;
    margin: 0 auto;
  }

  button {
    margin-top: 30px;
  }

  .forgotPasswordText {
    margin-top: 5px;
  }

  .resetLink {
    margin-left: 5px;
    text-decoration: none;
    color: var(--blue);

    &:hover {
      text-decoration: underline;
    }
  }
}
