.container {
  padding: 20px;
  .header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;
  }
  .tableHeader {
    background-color: #f3f6ff;
    .text {
      font-size: 18px;
      font-weight: bold;
      text-transform: uppercase;
    }
  }
}

.pageName {
  display: flex;
  gap: 10px;
  align-items: center;
}

.loader {
  height: calc(100vh - 80px);
  display: flex;
  align-items: center;
  justify-content: center;
}
.action {
  display: flex;
  gap: 20px;
  cursor: pointer;
  padding: 5px;
  justify-content: flex-end;
}

.subTableContainer {
  border: 1px solid #e8e8e8;
  .header {
    border-bottom: 1px solid #e8e8e8;
    padding-bottom: 15px;
    display: flex;
    justify-content: space-between;
  }
}

.imgCell {
  display: flex;
  gap: 10px;
  align-items: center;
  .imgContainer {
    height: 50px;
    width: 50px;
    .img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.serviceIconCell {
  display: flex;
  gap: 10px;
  align-items: center;
  svg {
    width: 50px;
    height: 50px;
  }
}
