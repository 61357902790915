.tip {
  cursor: pointer;
}

.model {
  margin-bottom: 20px;
  width: 550px;
  .example {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid #cccccc;
    padding: 5px;
    gap: 10px;
  }
}

.img {
  height: 500px;
  overflow: auto;
}

.modalHeader {
  display: flex;
  align-items: center;
  gap: 1rem;
}
