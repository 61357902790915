.form {
  width: 700px;
  display: flex;
  gap: 40px;
  flex-direction: column;
}

.line {
  display: flex;
  gap: 10px;
}

.lineInput {
  width: 50%;
}