.container {
  border: 1px solid #dddddd;
  border-radius: 8px;
  padding: 16px;

  .title {
    width: 100%;
    padding: 10px 0;
    border-bottom: 1px solid #dddddd;
    font-size: 24px;
  }
  .children {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
}
