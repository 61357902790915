.container {
  padding: 20px;

  .header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;
  }
}

.action {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
