.container {
  width: 1000px;
  display: flex;
  flex-direction: column;
  height: 80vh;
  gap: 5px;
  .tabContainer {
    margin-bottom: 20px;
  }
}

.infoContainer {
  display: flex;
  height: 100%;
  overflow-y: auto;
  flex-direction: column;
  gap: 14px;
  margin-bottom: 10px;
}

.tabItem {
  display: flex;
  gap: 12px;
}

::-webkit-scrollbar {
  width: 3px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #e4e4e4;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #cecece;
}

.selectIconItem {
  display: flex;
  align-items: center;
  gap: 10px;
  svg {
    height: 3rem;
    width: 3rem;
  }
}
