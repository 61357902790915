.form {
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin-bottom: 50px;
  .card {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }
}
