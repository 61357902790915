.headerContainer {
  border-bottom: 1px solid #e8e8e8;
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
  }
}
.body {
  padding: 30px;
  max-height: 600px;
  overflow: scroll;
}
