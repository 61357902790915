.card-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 30px;

  & > nav {
    display: flex;
    align-self: self-start;
    margin-bottom: 20px;
  }

  .cardsList {
    display: flex;
    max-width: 1520px;
    flex-wrap: wrap;
    gap: 30px;
  }
}
