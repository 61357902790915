.select {
  .title {
    margin-bottom: 14px;
  }
}

.selectIcon {
  width: 65px;
  height: 65px;
}

.selectText {
  margin-left: 14px;
}

.selectItemWrapper {
  display: flex;
  align-items: center;
}
