.bookTimeContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
}

.datePicker {
  margin-top: 10px;
  width: 320px;
  font-weight: 400;
  font-size: 1.1rem;
  line-height: 140%;
  overflow-wrap: break-word;
  text-align: left;
  cursor: pointer;
}

.datePickerExampleWrapper {
  position: relative;
  width: 320px;
}

.label {
  position: absolute;
  top: 25px;
  text-transform: uppercase;
}

.datePicker:focus {
  outline: none;
}

.customPopper {
  width: 105% !important;
  max-width: none !important;
  min-width: 100% !important;
  position: inherit !important;
  transform: none !important;
  margin-top: 10px;
  box-shadow: -5px 5px 5px #d8d8d8 !important;
}

.customPopper .react-datepicker {
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: -5px 5px 5px #d8d8d8;
}

.inputWrapper svg {
  position: absolute;
  right: 0;
  top: 65px;
}

.datePickerCalendar {
  border: 2px solid black !important;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
}

.datePickerCalendar div > div {
  background-color: white !important;
  border-bottom: none !important;
}

.datePickerCalendar div > div[aria-selected='true'] {
  background-color: #71e873 !important;
  color: black !important;
  border-radius: 0 !important;
}

.customPopper svg {
  display: none !important;
}

.datePickerCalendar .react-datepicker__children-container {
  width: 97% !important;
}

.invalid {
  border-bottom: 1px solid red;
}

.invalidLabel {
  color: red;
}

.modalActions {
  margin-top: 40px;
  display: flex;
  gap: 20px;
}
