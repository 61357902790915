@import '../../../variables.css';

.card-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 30px;

  & > nav {
    display: flex;
    align-self: self-start;
    margin-bottom: 20px;
  }
}

.card-wrapper {
  margin-right: 20px;
  border: 1px solid var(--ligth-gray);
  text-align: center;
  width: 400px;
}

.card-wrapper:hover {
  background-color: var(--primary-gray-hover);
}

.card-style {
  padding: 20px 20px;
  width: 100%;
  background-color: var(--primary-gray);
  color: white;
}

.cardsList {
  display: flex;
  max-width: 1520px;
  flex-wrap: wrap;
  gap: 30px;
}

.card-image {
  min-height: 200px;
}

.card-title {
  margin-top: 10px !important;
}
