.content {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  text-align: center;

  .spinner {
    height: inherit;
  }
}
